import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const ContactInfo = () => {
    const data = useStaticQuery(graphql`
        query ContactInfoQuery {
            site {
                siteMetadata {
                    title
                    description
                    name
                    email
                    phone
                }
            }
        }
    `);
    return (
        <div className="contact-info-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Mobile: <a href={`tel:+${data.site.siteMetadata.phone}`}>{data.site.siteMetadata.phone}</a></p>
                            <p>E-mail: <a href={`mailto:${data.site.siteMetadata.email}`}>{data.site.siteMetadata.email}</a></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="contact-info-box">
                            <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <div className="icon">
                                <i className='bx bx-time-five'></i>
                            </div>
                            <h3>Hours of Operation</h3>
                            <p>Monday - Friday: 08:00 am CST - 5:00 pm CST</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo