import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'

const ContactForm = () => {
    const services = [
        "Premade Spreadsheet",
        "Custom Spreadsheet",
        "Professional Development",
        "Other"
    ];

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Thank you for your interest in working with Smarter Spreadsheets.  Please fill out the form to let us know how we can work with you on your data management needs.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form 
                                id="contactForm"
                                name="smarter-contact-form"
                                method="POST"
                                action="/thank-you/"
                                data-netlify="true"
                                data-netlify-honeypot="lastname"
                            >
                                <input type="hidden" name="form-name" value="smarter-contact-form" />
                                <input type="hidden" name="subject" value="Sales inquiry from smarter-spreadsheets.com" />
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Your name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6 d-none">
                                        <div className="form-group">
                                            <input type="text" name="lastname" className="form-control" placeholder="Do not fill this out" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" className="form-control" required placeholder="Your email address" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="select-box">
                                        <div className="form-group">
                                            <select name="service_type" className="form-select" required placeholder="">
                                                <option value="" disabled selected hidden>Type of service you are interested in</option>
                                                {
                                                    services.map((service) => <option key={service} value={service}>{service}</option>)
                                                }
                                            </select>
                                        </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Write your message..." />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            <i className="flaticon-tick"></i> 
                                            Send Message <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm